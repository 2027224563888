.headerContainer,
.leftContainer {
    background-color: #fff;
    max-width: 480px;
    width: 100%;

}

* {
    font-family: "Roboto";
}
:root{
    --btncolor:#00223b;
}
*,
:after,
:before {
    box-sizing: border-box;
}

.leftContainer {
    min-height: 0%;
    position: relative;
    padding-bottom: 60px;
}
.mb_5{
    margin-bottom: 30px;
}
.Home_betCardTitle__ikhTv {
    border-bottom: 1px solid #690202;
    color: rgb(44, 44, 44);
    font-size: 0.65em;
    font-weight: 700;
    height: 30px;
    width: 100%;
}
.headerContainer {
    position: fixed;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 6%);
    height: 0px;
    padding: 0;
    top: 0;
    z-index: 2;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.navLogo>img {
    height: 36px;
    width: 36px;
}


.Home_playButton__I9-X2 {
    font-weight: 700;
    text-transform: uppercase;
    top: 0px;
}

.login-btn {
    margin-right: 10px;
    border: 2px solid #fff;
    color: #fff !important;
    border-radius: 3px;
    font-size: 1.5em;
    font-weight: 500;
    padding: 1px 10px;
}
.policy_details{
    padding:8px;

}
.policy_details_link{
    a {
        text-decoration: none;
    }
}
/* .Home_betCardTitle__qzquH {
    background:#00223b;
    color:#fff;
    }

   .Home_betCardTitle__qzquH span{
        background: #ffff;
    padding: 0px 5px;
    border-radius: 5px;
} */
.footer_menu{
    position: fixed;
    background: #00223b;
    width: 100%;
    max-width: 480px;
    padding: 12px;
    color: #fff;
    bottom: 0px;
}
.footer_menu a{
    color: #fff !important;
    background-color: #0757a0;
    padding: 2px 44px;
    border-radius: 7px;
    font-size: 23px;
}
.rightContainer {
    background: url(../../../public/Images/bgimage.png);
    background-color: #fff;
    background-size: cover;
    overflow: hidden;
    right: 0;
    z-index: 4;
}

.rightContainer {
    bottom: 0;
    left: 480px;
    position: fixed;
    top: 0;
    border-left: 10px solid #e0e0e0;
}

.rcBanner-footer>img {
    height: 25px;
    width: 25px;
    margin-top: 4px;
}

.rcBanner-img-container {
    bottom: 45%;
    position: absolute;
    width: 300px;
}

.rcBanner-img-container>img {
    height: auto;
    width: 400px;
    margin: auto;
}

.flex-center {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.rcBanner-text {
    bottom: 35%;
    font-family: "Roboto Condensed";
    font-size: 2em;
    font-weight: 400;
    position: absolute;
    text-transform: uppercase;
}

.rcBanner-text-bold {
    font-weight: 900;
}

.main-area {
    background-color: transparent;
    overflow: hidden;
    width: 100%;
}

.games-section {
    background: #fff;
    border-radius: 0;
    position: relative;
    width: 100%;
}

.p-3 {
    padding: 1rem !important;
}

.games-section-headline {
    color: #959595;
    font-size: .75em;
    font-weight: 400;
    line-height: 18px;
}

.games-window {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.gameCard-container {
    margin-top: 10px;
    justify-content: center;
    position: relative;
    width: 46.8%;
}

.blink {
    animation: animate 2s linear infinite;
}

@keyframes animate {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.gameCard {
    background-color: #fff;
    border-radius: 5px;
    display: block;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.whatapp_link {
    position: relative;
}
.games-section.p-3{
}
.padding_new{
    padding:15px 15px 100px;
  
}
.whatapp_link a {
    color: green !important;
    position: fixed;
    left: 380px;
    bottom: 9%;
    display: flex;
    align-items: center;
    font-size: 45px;
    padding: 12px 15px;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 0 10px #746e6e;
}

@media screen and (max-width:425px) {
    .whatapp_link a {
        left: 280px;
    }
}


.gameCard-image {
    border-radius: 5px;
    height: 70.33%;
    width: 100%;
}

.gameCard-image img {
    border-radius: 5px;
}

.gameCard-icon,
.gameCard-title {
    display: flex;
    justify-content: center;
}

.gameCard-title {
    border-radius: 0 0 5px 5px;
    border: 1px solid #e0e0e0;
    border-top: none;
    color: #2c2c2c;
    font-weight: 700;
    padding: 15px 20px 10px;
}

.games-section-title {
    color: #2c2c2c;
    font-size: 1em;
    font-weight: 100;
}

.rcBanner-footer {
    bottom: 40px;
    color: #2c2c2c;
    font-size: 1.5em;
    font-weight: 400;
    line-height: 32px;
    position: absolute;
    text-align: center;
}

.footer {
    /* background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)) !important; */
    border-radius: 0 !important;
    position: relative !important;
    width: 100% !important;
    text-decoration: black;
    padding: 1% !important;
}

.footer-2 {

    background-image: linear-gradient(to right, #74e933, #e9d629);
}

.footer-divider {

    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;

}

.footer-links>a {
    color: #050505 !important;
    display: flex !important;
    font-size: 1.3em !important;
    font-weight: 400 !important;
    margin-bottom: 10px !important;
}

.footer-text-bold {
    color: #050505;
    font-size: 1em;
    font-weight: 700;
}

.footer-text {
    color: #050505;
    font-size: .9em;
    font-weight: 400;
}

a:hover {
    color: #0056b3 !important;
    text-decoration: underline;
}

a {
    color: #007bff !important;
    text-decoration: none;
    background-color: transparent;
}



.gameCard-container .goverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA;
}

.text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.collapseCard-container {
    padding: 30px 20px 20px;
    background-color: #fafafa;
}

.collapseCard {
    position: relative;
    width: 100%;
}

.collapseCard-body {
    padding-left: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    align-items: center;
    display: flex;
}

.collapseCard-header {
    background-color: #fafafa;
    padding-left: 3px;
    padding-right: 3px;
    top: -13px;
    align-items: center;
    position: absolute;
    display: flex;
    justify-content: center;
}

.collapseCard-title {
    color: #676767;
    font-size: .7em;
    font-weight: 700;
    text-transform: uppercase;
}

.result-area{
    margin-bottom: 80px;
}
.alert-box {
    color: #21940a;
    background-color: #080006;
    border-color: #b8b265;
    border-radius: 0;
    padding: 9px 7px 2px 7px;
}


.header_top_message {
    background-color: #d9bb18;
    color: #fff !important;
    font-family: Poppins, sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 17px;
    max-width: 480px;
    padding: 8px;
    text-align: center;
    top: 10;
    width: 480px;
    width: 100%;
    z-index: 9999;
}


.w1 {
    display: flex;
    justify-content: center;
    margin-bottom: 3px;
}

.w2 {
    background-color: lightgreen;
    color: black;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    animation: 0s ease 0s infinite normal none running flash;
}

.w3 {
    font-weight: bold;

}


.Home_betCard_playerName__kOsaM.Home_betCard_playerName__57U-C{
    padding-left: 10px;
    font-size: .7em;
    font-weight: 500;
    line-height: 1px;
  }