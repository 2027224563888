.center_xy {
  align-items: center;
  display: flex;
  flex-direction: column;
}
a:hover {
  color: #6c7293 !important;
}

.text_bold {
  color: #2c2c2c;
  font-size: 1.2em;
  font-weight: 800;
}

.battle_input_header {
  font-weight: 700;
  text-transform: uppercase;
}

.battle_input_header {
  color: rgb(149, 149, 149);
  font-size: 0.75em;
  margin-bottom: -5px;
  margin-top: 5px;
}

.profile_wallet {
  height: 70px;
  width: 90% !important;
  mix-blend-mode: normal;
  opacity: 1;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: rgb(255, 255, 255);
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: rgb(224, 224, 224);
  border-right-color: rgb(224, 224, 224);
  border-bottom-color: rgb(224, 224, 224);
  border-left-color: rgb(224, 224, 224);
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.mytext {
  color: rgb(44, 44, 44);
  font-size: 1em;
  font-weight: 600;
  line-height: 21px;
}

.divider_x {
  background: #f1f1f1;
  width: 100%;
  height: 10px;
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: rgb(241, 241, 241);
}

.MuiSvgIcon_root {
  fill: currentcolor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0ms;
  transition-property: fill;
}

.MuiSvgIcon_colorAction {
  color: rgba(0, 0, 0, 0.54);
}

.position_relative {
  position: relative !important;
}

.moneyBox_header {
  color: rgb(149, 149, 149);
  font-size: 0.6em;
  font-weight: 500;
  text-transform: uppercase;
}

.moneyBox_text {
  color: rgb(44, 44, 44);
  font-size: 0.8em;
  font-weight: 900;
  position: relative;
  bottom: 4px;
}
.thin_divider_x {
  background-color: rgb(224, 224, 224);
  bottom: 0px;
  height: 1px;
  left: 57px;
  right: 0px;
}

.inactive_tab {
  display: none;
}

.active_tab {
  display: block;
}

.MuiFormControl_root {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
}

.MuiInputLabel_animated {
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.MuiInputLabel_formControl {
  top: 0;
  left: 0;
  /* position: absolute; */
  transform: translate(0, 24px) scale(1);
}

.MuiInputLabel_root {
  display: block;
  transform-origin: top left;
}

.MuiFormLabel_root {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.MuiInput_root {
  position: relative;
}

.MuiInputBase_root {
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
}

.MuiInput_underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #3f51b5;
  pointer-events: none;
}

.MuiInput_underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  pointer-events: none;
}

.MuiInputBase_input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

.MuiInputBase_root {
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
}

.kyc_select {
  bottom: 0;
  left: 0;
  max-width: 480px;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 70%;
  z-index: 4;
}

.kyc_select .overlay {
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  background-color: #2c2c2c;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s;
}

.kyc_select .box {
  border-radius: 16px 16px 0 0;
  box-shadow: 0 -3px 8px 0 rgb(0 0 0 / 12%);
  display: none;
  max-height: 88%;
  overflow-y: scroll;
  pointer-events: auto;
  -webkit-transform: translate3d(0, 360px, 0);
  transform: translate3d(0, 360px, 0);
  transition: -webkit-transform 0.2s cubic-bezier(0, 0, 0.3, 1) 0s;
  transition: transform 0.2s cubic-bezier(0, 0, 0.3, 1) 0s;
  transition: transform 0.2s cubic-bezier(0, 0, 0.3, 1) 0s,
    -webkit-transform 0.2s cubic-bezier(0, 0, 0.3, 1) 0s;
  width: 100%;
  will-change: transform;
}
.kyc_select_enter_done {
  display: block !important;
  -webkit-transform: translateZ(0) !important;
  transform: translateZ(0) !important;
}
.bg_white {
  background-color: #fff !important;
}
.kyc_select .header {
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px 10px 0 0;
  max-width: 480px;
  padding: 30px 20px;
  position: fixed;
  width: 100%;
  z-index: 3;
}
.kyc_select .header_text {
  color: #2c2c2c;
  font-size: 1.2em;
  font-weight: 600;
}
.flex_column {
  flex-direction: column !important;
}
.cxy,
.refer-button-copy {
  align-items: center;
  display: flex;
  justify-content: center;
}
.kyc_select_exit_done {
  display: none;
}
.kyc_select_exit,
.kyc_select_exit_done {
  -webkit-transform: translate3d(0, 360px, 0) !important;
  transform: translate3d(0, 360px, 0) !important;
}

.MuiInputBase_input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0 !important;
  height: 1.1876em !important;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  border-bottom: 2px solid #2c2c2c !important;
  -webkit-tap-highlight-color: transparent;
}
