h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 2.5rem;
  font-family: " ";
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.5rem !important;
  font-family: " ";
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

p {
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
  font-family: " ";
  font-size: 1rem !important;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

ul {
  margin-bottom: 1rem;
  margin-top: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

ol {
  margin-bottom: 1rem;
  margin-top: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

li {
  font-family: " ";
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.giveHover:hover {
  color: #0056b3;
  text-decoration: underline;
}

span {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

ul ul {
  margin-bottom: 0;
}

b {
  font-weight: bolder;
}

a {
  color: #007bff !important;
}

 
a:hover {
  color: #007bff !important;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e0e0e0 !important;
  border-radius: 0.25rem;
  border: 1px solid #2c2e33;
}
